
import axios from '@/plugins/axios'

const baseURL = process.env.VUE_APP_API_ALIANZAS;

const http = axios(baseURL);

export default {
    solicitud(payload) {
        return http.post(baseURL+'/solicitudes/crear', payload);
    }

    ,registar_visita(payload){
        return http.post(baseURL+'/visitas/crear', payload, {spinner: false});
    }
};