

<template>
  <div class="py-5 section-form bg-bpb-green-02">

    <h1 class="mb-5 text-white">
      Tu respaldo financiero para <br> llevar a tu empresa al siguiente <br> nivel está a un solo clic.
    </h1>



    <div class="container">
      <form class="p-5 rounded-5" novalidate  @submit.prevent="submitForm" >


        <div class="text-start row gy-3">
          <div class="col-sm-6">
            <label for="nombre" class="form-label text-bpb-primary-blue fw-light">Nombre del solicitante:</label>
            <input type="text" class="form-control" id="nombre" v-model="solicitud.nombre" placeholder="Ingresa tu nombre completo">
          </div>

          <div class="col-sm-6">
            <label for="celular" class="form-label text-bpb-primary-blue fw-light" >Celular:</label>
            <input type="tel" class="form-control" id="celular" v-model="solicitud.celular" placeholder="(_ _) _ _ _ _ _ _ _ _" maxlength="10">
          </div>

          <div class="col-sm-6">
            <label for="email" class="form-label text-bpb-primary-blue fw-light">Correo electrónico:</label>
            <input type="email" class="form-control" id="email" v-model="solicitud.email" placeholder="ejemplo@mail.com">
          </div>

          <div class="col-sm-6">
            <label for="tipogarantia_id" class="form-label text-bpb-primary-blue fw-light">¿Tienes una propiedad como garantía? :</label>
            <select id="tipogarantia_id" name="tipogarantia_id" class="form-select" v-model="solicitud.tipogarantia_id">
              <option :value="null" disabled>Seleccione</option>
              <option value="1">Casa</option>
              <option value="3">Departamento</option>
              <option value="7">Oficina</option>
              <option value="4">Local Comercial</option>
            </select>
          </div>

          <div class="col-sm-6">
            <label for="cp" class="form-label text-bpb-primary-blue fw-light">Código Postal del Inmueble:</label>
            <input type="text" class="form-control" id="cp" v-model="solicitud.cp" placeholder="Ingresa un cp">
          </div>

          <div class="col-sm-6">
            <label for="monto_prestamo" class="form-label text-bpb-primary-blue fw-light">¿Cuánto te gustaría recibir?:</label>
            <money
                class="form-control"
                :class="solicitud"
                name="monto_prestamo"
                id="monto_prestamo"
                placeholder="200,000 hasta 10,000,000"
                type="text"
                v-model="solicitud.monto_prestamo"
            ></money>
          </div>

          <div class="col-sm-6">
            <label for="propia" class="form-label text-bpb-primary-blue fw-light">¿A nombre de quién está el inmueble?:</label>
            <select id="propia"  name="propia" class="form-select" v-model="solicitud.propia">
              <option :value="null" disabled >Seleccione</option>
              <option value="1">Mío</option>
              <option value="0">Esposo/a</option>
              <option value="0">Padres</option>
              <option value="0">Hermanos</option>
              <option value="0">Hijos</option>
              <option value="0">Abuelos</option>
              <option value="0">Otro familiar</option>
              <option value="0">Empresa</option>
            </select>

          </div>

          <div class="col-sm-6">
            <label for="valuacion_cliente" class="form-label text-bpb-primary-blue fw-light">¿Cuánto vale mi inmueble?: <small>**Valor 2 veces el monto a pedir</small></label>
            <money
                class="form-control"
                name="valuacion_cliente"
                id="valuacion_cliente"
                placeholder="Valor real de tu propiedad"
                type="text"
                v-model="solicitud.valuacion_cliente"
            ></money>
            <div data-input="valuacion_cliente" class="bpb-invalid d-none" id="error_valuacion_cliente">
              La valuación debe ser al menos el doble del monto solicitado.
            </div>
          </div>
        </div>


        <button type="submit" class="btn btn-lg bpb-button-green-01 rounded-pill px-4 py-2 fw-bold mt-4" href="#solicitud" id="send_form"> Enviar solicitud</button>
      </form>
    </div>


  </div>
</template>

<script>

import alianzasApi from "@/api/alianzas.api";

export default {
  name: 'Solicitud',
  components: {},
  computed:{
    valuacion_menor_a_monto_solicitado(){
      return this.solicitud.monto_prestamo > this.monto_minimo &&  this.solicitud.valuacion_cliente < (this.solicitud.monto_prestamo * 2)
    }
  },

  data() {
    return {
      solicitud: {
        //Campos mostrados en formulario

        nombre: null,
        celular: null,
        email: null,
        tipogarantia_id: null,
        cp: null,
        valuacion_cliente: 0,
        propia: null,
        monto_prestamo: 0,

        //Campos no mostrados en formulario pero que son requeridos
        plazo: 1,
        acepta_politicas_privacidad: 1, //  acepta_terminos (asi estaba en ejemplo)
        entidad : 9,
        medioinformacion_id : process.env.VUE_APP_MEDIO_INFORMACION,
        tipo_persona : "PF",

      },
      monto_minimo: 200000,
      monto_maximo: 100000000
    }
  }

  ,mounted() {
    this.solicitud.medioinformacion_id = process.env.VUE_APP_MEDIO_INFORMACION;

    let utm = this.$marketin.campania();

    if (utm)
      this.solicitud.medioinformacion_id = utm;
  }

  ,methods: {

    resetForm(){
      this.solicitud.nombre = null
      this.solicitud.celular = null
      this.solicitud.email = null
      this.solicitud.tipogarantia_id = null
      this.solicitud.cp = null
      this.solicitud.valuacion_cliente = 0
      this.solicitud.propia = null
      this.solicitud.monto_prestamo = 0
    },

    get_uuid(){
      let uuid = window.localStorage.getItem('uuid');

      if (!uuid) {
        uuid = this.generarStringAleatorio();
        window.localStorage.setItem('uuid',uuid);
      }
      return uuid;
    },


    async submitForm(){

      let minimo_valuacion = (this.solicitud.monto_prestamo * 2);
      let minimo_valuacion_valiadacion = !minimo_valuacion ? '' : '|value_greate:'+minimo_valuacion+',$'+this.$helper.moneyFormat(minimo_valuacion);

      let monto_maximo = this.$helper.moneyFormat(this.monto_maximo);

      let validaciones = {
        nombre: 'required|max:255',
        celular: 'required|phone',
        email: 'required|email',
        tipogarantia_id: 'required',
        cp: 'required|numeric|max:5',
        valuacion_cliente: 'required|money' + minimo_valuacion_valiadacion,
        propia: 'required',
        monto_prestamo: 'required|money|value_greate:200000,$200000.00|value_less:'+this.monto_maximo+','+monto_maximo
      };
      
      let validator = await this.$validator(validaciones);

      if (!Object.values(validator).length)
        await this.crearSolicitud()
      else
        await this.registrar_error_validacion(validator)
    },

    async registrar_error_validacion(errores){
      try {

        const payload = {
          origen: window.location.origin,
          url: window.location.href,
          evento: 'CREAR_SOLICITUD_ERROR',
          request: this.solicitud,
          response: errores,
          errores: errores,
          response_code: 200,
          uuid: this.get_uuid(),
          utm: this.$marketin.utm()
        }

        window.dataLayer.push({
          event: 'lead_form_error',
          page: payload
        })

        await alianzasApi.registar_visita(payload)

      }catch (e){
        this.$log.error('Registrar eror validacion (error)', e);
      }
    },

    

    async crearSolicitud(){

      try {

        this.solicitud.uuid = this.get_uuid();
        this.solicitud.utm = this.$marketin.utm();

        await alianzasApi.solicitud(this.solicitud);

        window.dataLayer.push({
          event: 'lead_form',
          page: window.location.href
        })

        this.resetForm()

        this.$notify({
          group: 'alert'
          ,type: 'success'
          ,title: 'Enviar solicitud'
          ,text: 'Solicitud enviada correctamente'
          ,duration: 4000
        })

       await this.$router.push({name: 'gracias-por-registrarte'})

      }catch (e) {

        this.$log.error('Enviar solicitud Error', e);

        let message = 'Ocurrió un error, favor de intentar más tarde.'

        if (e.response && e.response.status === 400 && e.response.data.error && typeof Array.isArray(e.response.data.message) ) {

          message = ''

          if ( e.response.data.message)
            message += e.response.data.message

          message += '<br>'+Object.values(e.response.data.error).join("<br>")


        }

        this.$notify({
          group: 'alert'
          ,type: 'error'
          ,title: 'Error'
          ,text: message
          ,duration: 4000
        })
      }
    },
    generarStringAleatorio() {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let stringAleatorio = '';

      for (let i = 0; i < 100; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
        stringAleatorio += caracteres[indiceAleatorio];
      }

      return stringAleatorio;
    }

    ,validacion_valuacion_cliente() {
      //console.log('valuacion_menor_a_monto_solicitado', this.valuacion_menor_a_monto_solicitado)
      let input = document.querySelector('#valuacion_cliente')
      let error_valuacion_invalida = document.querySelector('#error_valuacion_cliente')

      if (this.valuacion_menor_a_monto_solicitado) {
        input.classList.add('bpb-invalid-input');
        error_valuacion_invalida.classList.remove('d-none');
      }else {
        error_valuacion_invalida.classList.add('d-none');
        input.classList.remove('bpb-invalid-input');
      }

    }

  }
  ,watch:{
    'solicitud.celular': {
      handler(newValue, oldValue) {
        this.solicitud.telefono = this.solicitud.celular
      },
    },
      // 'solicitud.monto_prestamo':{
      // //Cuando el usuario vaya ingresando el monto a solicitar validar que este en el rango permitido, si no es asi: mostrar error
      //   handler(newValue, oldValue) {
      //     this.validacion_valuacion_cliente()
      //   }
      // }

  }
}

</script>

<style scoped lang="scss">


.section-form form{
  background: white;
  margin-bottom: 60px;
}

::placeholder {
  color: #6c7586 !important;
}
</style>