

<template>
  <section class="container">

    <h1 class= "text-bpb-green-03 mb-4">Nuestros Valores</h1>


    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-md-center g-5">
      <div class="col">
        <div class="card h-100 bg-bpb-green-03 py-4 border-0 rounded-5">
          <div class="w-100">
            <img :src="static_url+'/Fortaleza.png'" alt="Bien para Bien" width="90">
          </div>
          <div class="card-body text-bpb-white">
            <h5 class="card-title">FORTALEZA FINANCIERA</h5>
            <p class="card-text fw-light">
              Contamos con una capacidad sólida
              y sostenible para cuidar de tus
              ﬁnanzas hasta en las condiciones
              económicas más adversas.
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100 bg-bpb-green-03 py-4 border-0 rounded-5">
          <div class="w-100">
            <img :src="static_url+'/Rapidez.png'" alt="Bien para Bien" width="90">
          </div>
          <div class="card-body text-bpb-white">
            <h5 class="card-title">RAPIDEZ</h5>
            <p class="card-text fw-light">
              Como empresa crediticia nos
              caracteriza nuestra agilidad
              asegurando una experiencia ﬂuida y
              sin contratiempos.
            </p>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card h-100 bg-bpb-green-03 py-4 border-0 rounded-5">
          <div class="w-100">
            <img :src="static_url+'/Tecnologia.png'" alt="Bien para Bien" width="90">
          </div>
          <div class="card-body text-bpb-white">
            <h5 class="card-title">TECNOLOGÍA</h5>
            <p class="card-text fw-light">
              Innovamos en agilizar los procesos
              utilizando uno de los más novedosos
              sistemas de machine learning para
              el proceso de entrevista.
            </p>
          </div>
        </div>
      </div>

    </div>



  </section>
</template>

<script setup>
const static_url =  process.env.VUE_APP_STATIC_URL;

</script>

<style scoped lang="scss">
section{
  padding: 50px 0;
}

</style>