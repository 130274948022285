
<template>
 <section class="background-image w-100">


   <div class="container-fluid">
       <div class="wrapper-content">
        <div class="w-100 d-flex">
          <div class="image">
            <img class="" :src="static_url+'/1000035439.png'" alt="Bien para Bien">
          </div>
        </div>
         <div class="content">
           <h1 id="title" class="mb-3 fw-bolder text-bpb-white">¡GRACIAS!</h1>

           <div class="card rounded-4 text-center">
             <div class="card-body">
               <p class="card-text fw-light">
                 Muy pronto nuestro equipo se pondrá en contacto contigo para verificar si tu solicitud fue aprobada, cuál es
                 tu línea de crédito y plan de pagos.
               </p>
             </div>
             <div class="card-footer bg-bpb-primary-blue rounded-bottom-4 text-bpb-white">

               <router-link to="/" role="button"
                            class="btn btn-link w-100 bg-transparent text-bpb-white fw-bold text-decoration-none"
               > REGRESAR AL INICIO
               </router-link>
             </div>
           </div>

           <div class="mt-4">
             <botones-redes-sociales color="white"></botones-redes-sociales>
           </div>
         </div>
       </div>


   </div>

 </section>


</template>

<script>

import BotonesRedesSociales from "@/components/BotonesRedesSociales.vue";

export default {
  name: 'TankYou',
  components: {BotonesRedesSociales},
  data(){
    return {
      static_url: process.env.VUE_APP_STATIC_URL
    }
  }
}

</script>

<style scoped lang="scss">
section{
  padding-top: 50px;
}
.card{
  max-width: 800px
}

.background-image{
  background-color: $bpb-green-02;
  background-image: url($VUE_APP_STATIC_URL + "/1000035441.png") ;
  background-position: center left;
  background-repeat: repeat;
}

.wrapper-content{
  display: inline-flex;
  }

.image {
  height: 100%;
  max-width: 400px;
  position: relative;
  z-index: 1;
  right: -20%;


  img {
    max-width: 100%;
    height: 100%;
  }
}

  .content{
    align-self: center;
    position: relative;
    right: 20%;
  }


@media (max-width: 1024px) {
  .image {
    right: -14%;
  }
  .content{
    right: 14%;
  }
}

@media (max-width: 768px) {
  .image {
    right: -5%;
  }
  .content{
    right: 5%;
  }

}


  @media (max-width: 425px) {

    section{
      padding-bottom: 50px;
    }

    .image{
      margin: auto;
    }


    .wrapper-content{
      display: block;
    }

    .image, .content{
      right: 0;
    }

  }


</style>