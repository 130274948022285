

<template>
  <div class="casos_exitos bg-bpb-green-02">
    <div class="container">

      <h1 class="text-center mb-5">Casos de éxito</h1>

      <div class="contenedor">
        <div class="casos">
          <div class="p-4" v-for="(caso, key) in casos_exitos" :key="key" :class="'caso'+(casos_exitos_seleccionado == key ? ' caso_seleccionado' : '')">
            <div class="cabecera d-flex justify-content-between">
             <div>
               <div class="icono me-3">
                 <img :src="static_url+'/Icono-Avatar.webp'" alt="Avatar">
               </div>
               <div class="info text-start">
                 <div class="nombre">{{ caso.nombre }}</div>
                 <div class="puesto">{{ caso.puesto }}</div>
               </div>
             </div>
              <div class="icono">
                <a :href="caso.url" target="__blank">
                  <img class="no-reproducir float-end" :src="static_url+'/Icono-Reproducir-Verde.png'" alt="reproducir">
                </a>
                <img class="reproducir float-end"  :src="static_url+'/Icono-Reproducir.png'" alt="reproducir">
              </div>
            </div>
            <div class="descripcion text-start mt-4">
              "{{ caso.descripcion }}"
            </div>
          </div>
        </div>
      </div>

      <div class="botones">
        <div v-for="(caso, key) in casos_exitos" :key="key" :class="'item'+(casos_exitos_seleccionado == key ? ' item_seleccionado' : '')" @click="seleccionar_caso(key)"></div>
      </div>


    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";
const static_url =  process.env.VUE_APP_STATIC_URL;

const casos_exitos = [
  {
    nombre: "Rodrigo vicencio",
    puesto: "Promotor",
    descripcion: "Lo que me ha ayudado mucho son los videos, que yo también comparto a la gente. Aparte de trabajar en otra cosa es un ingreso adicional. Las ganancias son muy buenas, muy recomendable.",
    url: "https://www.youtube.com/watch?v=cej42C5VYec&t=1s"
  },
  {
    nombre: "Miguel Sánchez",
    puesto: "Acreditado",
    descripcion: "Teníamos muy mal buró de crédito. No éramos una mala empresa pero teníamos una situación que Bien para Bien entendió perfectamente. El proceso fue bastante transparente y bien explicado paso por paso.",
    url: "https://www.youtube.com/watch?v=iwNzVDw4uhk&t=13s"
  },
  {
    nombre: "Martín Montoya",
    puesto: "Acreditado",
    descripcion: "Es una financiera muy eficaz, te da soluciones y alternativas. De acuerdo a tu necesidad se puede adaptar el préstamo que te hagan. Puedo seguir con mis proyectos empresariales. Estoy muy contento con Bien para Bien.",
    url: "https://www.youtube.com/watch?v=Q6Nuo10CgNA&t=86s"
  }
];

const casos_exitos_seleccionado = ref(0);
var casos_exitos_elementos;
setTimeout(() => {
  casos_exitos_elementos = document.querySelectorAll('.caso');
  seleccionar_caso(1);
},200);

const seleccionar_caso = (index) => {
  casos_exitos_elementos.forEach((item, i) => {
    const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    let desplazamiento = screenWidth > 932 ? -109 : -110;

    const transform = `translateX(${index * desplazamiento}%)`;
    item.style.transform = transform;
  })
  casos_exitos_seleccionado.value = index;
}

</script>

<style scoped lang="scss">
.casos_exitos {
  padding-top: 100px;
  color: #fff;
  padding-bottom: 40px;
  overflow: auto;
  position: relative;

  .container {
    .titulo {
      font-size: 3em;
      font-weight: bold;

      margin-top: 60px;
      margin-bottom: 40px;
    }

    .contenedor {
      width: 90%;
      margin: auto;
      overflow: hidden;
      .casos {
        width: 100%;
        margin-left: 33%;
        margin-bottom: 40px;
        // overflow: hidden;
        display: flex;

        .caso {
          width: 400px;
          font-size: 1.2em;
          border: solid 1px #fff;
          border-radius: 20px;
          margin-right: 30px;
          padding: 10px;
          flex: 0 0 50%;
          transition: transform 0.3s ease;

          .cabecera {
            margin-bottom: 10px;

            .icono, .info {
              display: inline-block;
            }

            .icono {
              width: 50px;
              vertical-align: top;

              img {
                width: 100%;
                max-width: 100%;
                height: auto;
              }
            }

            .info {
              font-weight: bold;

              .nombre, .puesto {
                width: 100%;
              }

              .nombre {
                color: #fff;
              }

              .puesto {
                color: $bpb-green-01;
              }
            }
          }

          .descripcion {
            font-size: 0.9em;
            font-style: italic;
            text-justify: auto;
            font-weight: lighter;
          }

          .reproducir {
            display: block;
          }

          .no-reproducir {
            display: none;
          }
        }

        .caso:last-child {
          margin-right: 0px;
        }

        .caso_seleccionado {
          background-color: $bpb-white;


          .cabecera {
            .info {
              .nombre {
                color: $bpb-primary-blue;
              }

              .puesto {
                color: $bpb-green-03;
              }
            }
          }

          .descripcion{
            color: $bpb-primary-blue;
          }

          .reproducir {
            display: none !important;
          }

          .no-reproducir {
            display: block !important;
          }
        }
      }
    }

    .botones {
      $items-size: 30px;

      .item {
        width: $items-size;
        height: $items-size;
        background-color: #999;
        border-radius: 20px;
        margin-right: 10px;
        display: inline-block;
        cursor: pointer;
      }

      .item:last-child {
        margin-right: 0px;
      }

      .item_seleccionado {
        background-color: $bpb-primary-blue;
      }
    }

    .conocer_mas {
      text-align: center;
      padding-top: 40px;

      button {
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 932px) {
  .casos_exitos {
    .container {
      .contenedor {
        .casos {
          margin-left: 10%;
          .caso {
            flex: 0 0 80%;
            .descripcion {
              font-size: 0.8em;
            }
          }
        }
      }
    }
  }
}
</style>