

<template>
  <section class="bg-bpb-primary-blue">

    <div class="container">
      <h1 class="text-bpb-white mb-5">Beneficios</h1>


      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-md-center g-5">
        <div class="col">
          <div class="card h-100 border-0 rounded-5 bg-bpb-white">
            <img :src="static_url+'/Foto-02.png'" class="card-img-top" alt="Bien para Bien">
            <div class="card-body">
              <h5 class="card-title">
                Nuestro proceso es 100% digital
              </h5>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card h-100 border-0 rounded-5 bg-bpb-white">
            <img :src="static_url+'/Foto-03.png'" class="card-img-top" alt="Bien para Bien">
            <div class="card-body">
              <h5 class="card-title">
                Consultoría especializada orientada a tus objetivos
              </h5>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card h-100 border-0 rounded-5 bg-bpb-white">
            <img :src="static_url+'/Foto-04.png'" class="card-img-top" alt="Bien para Bien">
            <div class="card-body">
              <h5 class="card-title">
                Conﬁanza y seguridad garantizada
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>





  </section>
</template>

<script setup>
const static_url =  process.env.VUE_APP_STATIC_URL;
</script>

<style scoped lang="scss">
section{
  padding: 50px 0;
}

</style>