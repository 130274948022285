
<template>
  <section class="bg-bpb-green-03 section" >
    <div class="container">
        <h1 class="text-bpb-white">Adquiere tu crédito en 3 pasos:</h1>
      <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-md-center g-0">
        <div class="col">
          <div class="card h-100 mb-3 border-0 bg-transparent credit-steps-card p-5">
            <div class="mb-3 w-100">
              <img :src="static_url+'/Paso-01.png'" class="mx" alt="Bien para Bien" width="90">
            </div>
            <h5 class="card-title text-bpb-white">Realiza tu solicitud en línea</h5>
          </div>
        </div>
        <div class="col">
          <div class="card h-100 mb-3 border-0 bg-transparent credit-steps-card p-5">
            <div class="mb-3 w-100">
              <img :src="static_url+'/Paso-02.png'" class="mx" alt="Bien para Bien" width="90">
            </div>
            <h5 class="card-title text-bpb-white">Reúne la documentación para tu autorización</h5>
          </div>
        </div>

        <div class="col">
          <div class="card h-100 mb-3 border-0 bg-transparent credit-steps-card p-5">
            <div class="mb-3 w-100">
              <img :src="static_url+'/Paso-03.png'" class="mx" alt="Bien para Bien" width="90">
            </div>
            <h5 class="card-title text-bpb-white">Prepárate para la firma</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
const static_url =  process.env.VUE_APP_STATIC_URL;

</script>

<style scoped lang="scss">

section{
  padding: 150px 0;
}

.credit-steps-card:hover{
  cursor: pointer;
  background: rgba(252, 252, 252, 0.38) !important;
}

</style>