
<template>

  <div>
    <section class="bg-bpb-primary-blue text-bpb-white">
      <div class="container fw-light text-bpb-white">
        <div class="row mb-4">
          <div class="col-md-4 mb-4 text-start">

            <div class="img-thumb-container mb-3">
              <img :src="static_url+'/BPB-Logo-Principal.png'" class="img-thumb bg-transparent border-0" alt="Bien para Bien">
            </div>

            OFICINAS: Orizaba 31, Col. Roma Norte, Alcaldía Cuauhtémoc, C.P. 06700 Ciudad de México

            <div class="my-3">
              <botones-redes-sociales></botones-redes-sociales>
            </div>

          </div>

          <div class="col-md-4 mb-4  text-start">
            <h5 class="text-bpb-green-02 mb-4">CONTACTO</h5>
            <a class="footer-link text-decoration-none d-block mb-3 whatsapp-link fw-light h5" href="https://wa.me/5549568857?text=Hola, solicito más información" target="_blank">WhatsApp: 55 4956 8857</a>
            <a class="footer-link text-decoration-none d-block mb-3 whatsapp-link fw-light h5" href="tel:8006000272"> Teléfono: 800 6000 bpb (272)</a>
            <div class="h5 fw-light mb-5">Lunes a Viernes de 9 a 6pm</div>

            <a class="btn btn-link img-thumb-buro mt-3"  :href="bpb_portal_web_url+'/une#buro'" target="_blank" role="button">
              <img :src="static_url+'/Boton-Buro.png'" class="img-thumb bg-transparent border-0" alt="Bien para Bien">
            </a>
          </div>

          <div class="col-md-4 mb-4 text-sm-end">
            Bien para Bien, Sociedad Anónima Promotora de Inversión de
            Capital Variable, Sociedad Financiera de Objeto Múltiple, Entidad
            No Regulada, para su constitución y operación con tal carácter,
            no requiere de autorización de la Secretaría de Hacienda y
            Crédito Público, no obstante, se encuentra sujeta a la
            supervisión de la Comisión Nacional Bancaria y de Valores,
            únicamente para efectos de lo dispuesto por el artículo 56 de la
            Ley General de Organizaciones y Actividades Auxiliares del
            Crédito. Bien para Bien® SAPI de CV SOFOM ENR. Prohibida la
            reproducción total o parcial del contenido de este sitio.
          </div>

          <div class="col-md-12 mb-4 text-center">

            <a class="footer-link fw-light mt-3 h5" :href="bpb_portal_web_url+'/privacy'" target="_blank">Aviso de privacidad</a> <br>

            <div class="mt-3">
              <a class="footer-link fw-light h5" :href="bpb_portal_web_url+'/une'" target="_blank">Condusef</a>
            </div>

            <div class="h5 fw-light my-3"> © Bien para Bien® SAPI de CV SOFOM ENR. Todos los derechos reservados. 2024</div>


            <a role="button" class="btn btn-link footer-link fw-bold fs-5" data-bs-toggle="modal" data-bs-target="#infoCat">Consulta los costos y comisiones de los productos.</a>

          </div>
        </div>

      </div>
    </section>

    <!--modal-->

    <!-- Modal -->
    <div class="modal fade modal-dialog-scrollable" id="infoCat" tabindex="-1" aria-labelledby="infoCat" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="exampleModalLabel">Información adicional:</h1>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            CAT Promedio informativo y para fines de comparación 36.4% para un crédito por un monto de $1,000,000 m.n. a 60
            meses calculado a junio 2022, el pago promedio mensual podría ser de $32,405 m.n.(sin accesorios).
            Este puede variar dependiendo del monto, plazo y perfil del cliente al momento de solicitar un crédito.
            Tasas desde 27% al 33% anual. Plazos de pago de 1 a 5 años. Monto de $200 mil a $5 millones de pesos.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary text-bpb-white" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>


    <!--modal-->
  </div>



</template>

<script setup>
import BotonesRedesSociales from "@/components/BotonesRedesSociales.vue";
const static_url =  process.env.VUE_APP_STATIC_URL;
const bpb_portal_web_url =  process.env.VUE_APP_BPB_PORTAL_WEB_URL;
</script>


<style scoped lang="scss">

section{
  padding: 50px 0;
}


.social-media-btn{
  margin-right: 8px;
}
.img-thumb-container{
  width: 130px ;
}
.img-thumb-buro{
  width: 180px;
}

.img-thumb{
  width: 100%;
  height: auto;
  max-height: 100%;
}

.row .row-col-1{
  font-size: 11px;
}

.row .row-col-2{
  font-size: 8px;
}

.row .row-col-3{
  font-size: 14px;
  font-weight: bold;

}

.footer-link{
  text-decoration: none;
  color: $bpb-white;
}

.footer-link:hover{
  color: #b6b5b5 !important;
}


</style>