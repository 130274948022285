import Vue from 'vue'
import campanias from '@/config/campanias';

const logger =  {
	install(Vue, options) {
		Vue.prototype.$marketin = {
            utm: function() {
                let utm = 'desconocido';
                
                let tmp_utm = window.location.href;
                let inicio = tmp_utm.indexOf('utm_source');

                if (inicio != -1) {
                    Vue.prototype.$log.info('Se encontro utm_source')
                    tmp_utm = tmp_utm.substring(inicio);
                    inicio = tmp_utm.indexOf('=');
                    tmp_utm = tmp_utm.substring(inicio+1);
                    let final = tmp_utm.indexOf('&');

                    if (final > -1)
                        tmp_utm = tmp_utm.substring(0, final);

                    if (tmp_utm)
                        utm = tmp_utm;

                    window.sessionStorage.setItem('utm', utm);
                    return utm;
                }

                Vue.prototype.$log.info('No se encontro utm_source, se verifica si existe en la sesión utm');
                let utm_guardado = window.sessionStorage.getItem('utm');

                if (utm_guardado) {
                    Vue.prototype.$log.info('Se encontró utm guardado');
                    utm = utm_guardado;
                }
                
                return utm;
            },
            campania: function() {
                let campania = null;
                let url = window.location.href.toLowerCase();

                // Vue.prototype.$log.info('campania: ', campanias);
                for(let i=0; i<campanias.length; i++) {
                    let encontrado = true;

                    for(let a=0; a<campanias[i].keys.length; a++) {
                        if (url.indexOf(campanias[i].keys[a]) == -1) {
                            encontrado = false;
                            break;
                        }
                    }

                    if (!encontrado)
                        continue;

                    Vue.prototype.$log.info('Campaña encontrada: '+campanias[i].campania);
                    campania = campanias[i];
                    break;
                };

                if (!campania) {
                    Vue.prototype.$log.info('No se hizo match con ninguna campaña');
                    return parseInt(process.env.VUE_APP_MEDIO_INFORMACION);
                }

                let id = campania[process.env.VUE_APP_ENV];
                return id ? id : parseInt(process.env.VUE_APP_MEDIO_INFORMACION);
            }
        }
	}
}

Vue.use(logger);