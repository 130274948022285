
<template>

  <div>

    <!--- NOSOTROS -->
    <nosotros></nosotros>

    <!--- FORMULARIO SOLICITUD DE CRÉDITO -->
    <solicitud id="solicitud"></solicitud>

    <!--- PASOS PARA ADQUIRIR EL CRÉDITO -->
    <pasos-credito></pasos-credito>

    <!--- NUESTROS VALORES -->
    <valores></valores>

    <!--- BENEFICIOS -->
    <beneficios></beneficios>

    <!--- CASOS DE ÉXITO -->
    <casos-exito></casos-exito>

    <!--- NUESTROS ALIADOS -->
    <aliados></aliados>


    <!--- PRODUCTOS -->
    <productos></productos>


    <!--- FOOTER -->
    <footer-app></footer-app>


    <button type="button" class="btn btn-lg shadow sticky-button rounded-circle position-fixed end-0 sticky-bottom m-5" v-if="buttonScrollToTop" @click="toTop">
      <i class="bi bi-arrow-up"></i>
    </button>

  </div>

</template>

<script>

import Aliados from "@/pages/home/Aliados.vue";
import Solicitud from "@/pages/home/Solicitud.vue";
import PasosCredito from "@/pages/home/PasosCredito.vue";
import Valores from "@/pages/home/Valores.vue";
import Beneficios from "@/pages/home/Beneficios.vue";
import CasosExito from "@/pages/home/CasosExito.vue";
import Productos from "@/pages/home/Productos.vue";
import FooterApp from "@/pages/home/FooterApp.vue";
import Nosotros from "@/pages/home/Nosotros.vue";
import alianzasApi from "@/api/alianzas.api";


export default {
  name: 'Home',
  components: {Aliados, Solicitud, PasosCredito, Valores, Beneficios, CasosExito, Productos, FooterApp, Nosotros},
  data() {
    return {
      buttonScrollToTop: false
    }
  },
  mounted() {

    window.addEventListener('scroll', this.handleScroll)

    this.registrarVisita()

    let campania = this.$marketin.campania();
  }

  ,methods:{
    handleScroll: function () {
      this.buttonScrollToTop = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20

    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    async registrarVisita(){
      try {
        let uuid = window.localStorage.getItem('uuid');

        if (!uuid) {
          uuid = this.generarStringAleatorio();
          window.localStorage.setItem('uuid',uuid);
        }

        let utm = this.$marketin.utm();

        const payload = {
          origen: window.location.origin,
          url: window.location.href,
          response_code: 200,
          uuid: uuid,
          utm: utm
        }
       await alianzasApi.registar_visita(payload)

      }catch (e){
        this.$log.error('RegistrarVisita Error', e);
      }
    },
    generarStringAleatorio() {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let stringAleatorio = '';

      for (let i = 0; i < 100; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
        stringAleatorio += caracteres[indiceAleatorio];
      }

      return stringAleatorio;
    }
  }

}

</script>

<style scoped lang="scss">

.sticky-button{
  background: $bpb-green-01 !important;
  color: $bpb-white;
  font-weight: bold;
}

.sticky-button:hover{
  color: $bpb-primary-blue;
}



</style>