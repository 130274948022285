import Vue from 'vue'
import VueRouter from 'vue-router'
import TankYou from "@/pages/TankYou.vue";
import Home from "@/pages/Home.vue";
import Solicitud from "@/pages/home/Solicitud.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/gracias-por-registrarte',
    name: 'gracias-por-registrarte',
    component: TankYou
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition){
      return savedPosition
    }else if (to.hash) {
      const position = {selector: to.hash}

      if (document.querySelector(to.hash)) {
        return position
      }

      return false
    }
  }
})

router.afterEach((to, from) => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'pageView',
      page: to.fullPath
    })

    window.dataLayer.push({
      event: 'content-view',
      page: to.fullPath
    })
  }
  
  if (window.lintrk)
    window.lintrk('track', { conversion_id: '6137234' });
});

export default router
