
<template>
  <section>
    <div class="container">

      <h1 class="text-center text-bpb-green-03 mb-5">Empresas que son nuestros aliados</h1>

      <div class="image-wrapper" v-for="aliado of aliados">
        <img :src="static_url+aliado.img" :alt="aliado.titulo">
      </div>


    </div>
  </section>
</template>

<script>

export default {
  name: 'Aliados',
  data(){
    return {
      static_url:process.env.VUE_APP_STATIC_URL,
      aliados: [
        {
          titulo: 'Condusef',
          img: '/Logo-Condusef.png'
        },
        {
          titulo: 'DFC',
          img: '/Logo-DFC.png'
        },

        {
          titulo: 'CNBV',
          img: '/Logo-CNBV.png'
        },
        {
          titulo: 'SOC',
          img: '/Logo-SOC.png'
        },
        {
          titulo: 'Edge Logo Hi-Res',
          img: '/Logo-Edge.png'
        },
        {
          titulo: 'Dondé',
          img: '/logo_donde_color.webp'
        },
        {
          titulo: 'Kapitalizer',
          img: '/Logo-Kapitalizer.png'
        },
        {
          titulo: 'Creditaria',
          img: '/Logo-Creditaria.png'
        },
        {
          titulo: 'Buro',
          img: '/Logo-Buro.png'
        },




      ]
    }
  }
}

</script>
<style scoped lang="scss">


section{
  padding: 60px 0;
}



.image-wrapper{
  display: inline-block;
  height: 6em;
  margin: 20px 20px;


  img{
    max-height: 100%;
    width: auto;
    max-width: 220px;
  }
}


@media (max-width: 575.98px) {
  .image-wrapper{
    height: 4em;
  }
}

</style>