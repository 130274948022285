<template>
  <div id="app">
    <notifications group="alert" position="top right"/>
    <notifications group="notifications" position="top center"/>

    <nav class="navbar sticky-top py-3" id="app-nav">
      <div class="container-fluid">
        <div class="w-100 text-center">
          <router-link  class="navbar-brand mx-3" to="/">
            <img :src="static_url+'/BPB-Logo-Principal.png'" class="mx" alt="Bien para Bien" width="90">
          </router-link>
        </div>

      </div>
    </nav>

    <div id="body-app">
      <router-view/>
    </div>

    <div class="loading"></div>
  </div>
</template>

<script>

import alianzasApi from "@/api/alianzas.api";

export default {
  name: 'App',
  data() {
    return {
      static_url: process.env.VUE_APP_STATIC_URL,
    }
  },

  mounted() {
    // this.registrarVisita()
  }
  ,methods:{

    async registrarVisita(){
      try {
        let uuid = window.localStorage.getItem('uuid');

        if (!uuid) {
          uuid = this.generarStringAleatorio();
          window.localStorage.setItem('uuid',uuid);
        }

        const payload = {
          origen: window.location.origin,
          url: window.location.href,
          response_code: 200,
          uuid: uuid,
          utm: this.$marketin.utm()
        }

        await alianzasApi.registar_visita(payload)

      }catch (e){
        this.$log.error('RegistrarVisita Error', e);
      }
    },
    generarStringAleatorio() {
      const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let stringAleatorio = '';

      for (let i = 0; i < 100; i++) {
        const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
        stringAleatorio += caracteres[indiceAleatorio];
      }

      return stringAleatorio;
    }
  }
  ,watch:{
    $route (to, from){
     this.registrarVisita()
    }
  }
}
</script>

<style lang="scss">
#app-nav{
  background: $bpb-primary-blue !important;
}
</style>
