export default [
  {
    campania: "google 01",
    local: 109,
    test: 109,
    production: 1029,
    keys: [
      "googleads",
      "cuadrangulo",
      "persoamoral",
      "leads"
    ]
  },
  {
    campania: "linkedin 01",
    local: 110,
    test: 110,
    production: 1030,
    keys: [
      "linkedin",
      "video",
      "empresa"
    ]
  },
  {
    campania: "linkedin 02",
    local: 111,
    test: 111,
    production: 1031,
    keys: [
      "linkedin",
      "video",
      "verde"
    ]
  }
]