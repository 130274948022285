
<template>
  <div>
    <a class="btn btn-link rounded-circle social-media-btn" :class="{'social-media-btn-white': color == 'white'}" href="https://www.facebook.com/BienparaBienOficial/" target="_blank" role="button">
      <img :src="static_url+'/Icono-Facebook.png'" alt="Bien para Bien Facebook">
    </a>

    <a class="btn btn-link rounded-circle social-media-btn" :class="{'social-media-btn-white': color == 'white'}" href="https://www.instagram.com/bienparabien/" target="_blank" role="button">
      <img :src="static_url+'/Icono-Instagram.png'" alt="Bien para Bien Instagram">
    </a>

    <a class="btn btn-link rounded-circle social-media-btn" :class="{'social-media-btn-white': color == 'white'}" href="https://twitter.com/bienparabien" target="_blank" role="button">
      <img :src="static_url+'/Icono-X.png'" alt="Bien para Bien X">
    </a>

    <a class="btn btn-link rounded-circle social-media-btn" :class="{'social-media-btn-white': color == 'white'}" href="https://www.youtube.com/c/BienparaBien" target="_blank" role="button">
      <img :src="static_url+'/Icono-YouTube.png'" alt="Bien para Bien Youtube">
    </a>

    <a class="btn btn-link rounded-circle social-media-btn" :class="{'social-media-btn-white': color == 'white'}" href="https://www.linkedin.com/company/bienparabien/" target="_blank" role="button">
      <img :src="static_url+'/Icono-LinkedIn.png'" alt="Bien para Bien Linkedin">
    </a>


    <a class="btn btn-link rounded-circle social-media-btn" :class="{'social-media-btn-white': color == 'white'}" href="https://wa.me/5549568857?text=Hola, solicito más información" target="_blank" role="button">
      <img :src="static_url+'/Icono-WhatsApp.png'" alt="Bien para Bien Linkedin">
    </a>
  </div>

</template>

<script>
export default {
  name: 'BotonesRedesSociales',
  props: {
    color: {
      type: String,
      required: false,
      default: 'green'
    }
  }
  ,data(){
    return {
      static_url: process.env.VUE_APP_STATIC_URL
    }
  }
}
</script>

<style scoped lang="scss">

.social-media-btn-white{
  filter: brightness(0) invert(1);
  -webkit-filter: brightness(0) invert(1);
}
.social-media-btn{
  margin-right: 8px;
  display: inline-block;
  height: 42px;

  img{
    height: 100%;
    width: auto;
  }
}

</style>


