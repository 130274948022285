import Vue from 'vue'
import axios from 'axios'

export default function(baseURL, beforeSend) {
	const http = axios.create({ baseURL });

	let axiosPeticiones = 0;

	http.interceptors.request.use(config => {

		let token = process.env.VUE_APP_API_ALIANZAS_TOKEN;
		config.headers.Authorization = 'Bearer '+token;
		let showSpinner = true;

		if (typeof(config.spinner) != 'undefined')
			showSpinner = config.spinner;

		if (showSpinner) {
			if (axiosPeticiones == 0 && document.querySelectorAll('.loading').length > 0) {
				document.querySelectorAll('.loading')[0].style.display = 'block';
			}

			axiosPeticiones++;
		}

		if (typeof beforeSend == 'function')
			config = beforeSend(config);

		return config;
	});

	http.interceptors.response.use(response => {
		let hideSpinner = true;

		if (response.config && typeof(response.config.spinner) != 'undefined')
			hideSpinner = response.config.spinner;

		if (hideSpinner && axiosPeticiones > 0) {
			axiosPeticiones--;

			if (axiosPeticiones == 0 && document.querySelectorAll('.loading').length > 0) {
				document.querySelectorAll('.loading')[0].style.display = 'none';
			}
		}

		return response;
	}, async (err) => {
		let hideSpinner = true;

		if (err.config && typeof(err.config.spinner) != 'undefined')
			hideSpinner = err.config.spinner;

		if (hideSpinner) {
			axiosPeticiones--;

			if (axiosPeticiones == 0 && document.querySelectorAll('.loading').length > 0) {
				document.querySelectorAll('.loading')[0].style.display = 'none';
			}
		}

		if (err.response && err.response.status == 401) {
			let data = null;
			if (err.response.data.error && err.response.data.error) {
				data = err.response.data.error
			}else if (err.config.responseType) {
				if (err.config.responseType == 'arraybuffer') {
					data = JSON.parse(Buffer.from(err.response.data).toString('utf8')).error;
				}else if (err.config.responseType == 'blob') {
					data = JSON.parse(await err.response.data.text()).error;
				}
			}

			 if (err.response.data && !err.response.data.error && !err.response.data.message){
				Vue.prototype.$helper.showMessage('Acceso denegado','No tienes los permisos suficientes','error','alert');

				return Promise.reject(err);
			}else {
				return Promise.reject(err);
			}
		}else {
			return Promise.reject(err);
		}
	});

	return http;
};