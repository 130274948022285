
<template>
  <section class="bg-bpb-green-03">
   <div class="container-fluid">
     <h1 class="text-bpb-white text-center mb-5">Productos</h1>


     <!-- PRODUCTO BIEN PARA BIEN -->
     <div class="card border-0 rounded-4 mb-3 mx-auto" v-if="productoActivo === 'bien-para-bien'">
       <div class="row g-0">
         <div class="col-md-3">
           <div class="bg-bpb-green-02 d-flex h-100 rounded-start-4 p-3">
             <img :src="static_url+'/Bien-VerdeO.png'" class="m-auto" alt="Bien para Bien" />
           </div>
         </div>
         <div class="col-md-8 p-3">
           <div class="card-body text-start">
             <h4 class="text-bpb-green-02 fw-bold mb-4">BIEN PARA BIEN</h4>

             <ul>
               <li class="h5 fw-light">Crédito de liquidez desde 200 mil hasta 7 mdp.</li>
               <li class="h5 fw-light">Gasto único de administración desde el 4.9%-6%.</li>
               <li class="h5 fw-light">Plazo hasta 5 años.</li>
               <li class="h5 fw-light">Con garantía** fiduciaria.</li>
               <li class="h5 fw-light">Aforo desde 2 a 1.</li>
               <li class="h5 fw-light">Ingresos comprobables.</li>
               <li class="h5 fw-light">Edad desde 18 a 60 Años.</li>
               <li class="h5 fw-light">Para todo tipo de persona ﬁscal.</li>
               <li class="h5 fw-light">Tasas del 27% al 33%.</li>
             </ul>

             <span class="fw-light mt-3">** Departamento, Oficina, Local comercial y Casa</span>

           </div>
         </div>
       </div>
     </div>
     <!-- PRODUCTO BIEN PARA BIEN -->

     <!-- PRODUCTO LIBRE SIN GARANTIA -->
     <!--
     <div class="card border-0 rounded-4 mb-3 mx-auto" v-if="productoActivo === 'libre-sin-garantia'">
       <div class="row g-0">
         <div class="col-md-3">
           <div class="bg-bpb-green-02 d-flex h-100 rounded-start-4 p-3">
             <img :src="static_url+'/Bien-VerdeO.png'" class="m-auto" alt="Bien para Bien" />
           </div>
         </div>
         <div class="col-md-8 p-3">
           <div class="card-body text-start">
             <h4 class="text-bpb-green-02 fw-bold mb-4">LIBRE SIN GARANTÍA</h4>

             <ul>
               <li class="h5 fw-light">Crédito de liquidez desde 50mil hasta 3 MDP.</li>
               <li class="h5 fw-light">Comisión por apertura desde el 4.9%-6%.</li>
               <li class="h5 fw-light">Plazo hasta 24 meses.</li>
               <li class="h5 fw-light">Sin garantía.</li>
               <li class="h5 fw-light">Ingresos comprobables de 50 mil.</li>
               <li class="h5 fw-light">Buen Buró.</li>
               <li class="h5 fw-light">PFAE, RIF o PM.</li>
               <li class="h5 fw-light">Tasas del 26% al 33%.</li>
             </ul>
           </div>
         </div>
       </div>
     </div>
     -->
     <!-- PRODUCTO LIBRE SIN GARANTIA -->

     <!-- PRODUCTO PREMIUM -->
     <div class="card border-0 rounded-4 mb-3 mx-auto" v-if="productoActivo === 'premium'">
       <div class="row g-0">
         <div class="col-md-3">
           <div class="bg-bpb-green-02 d-flex h-100 rounded-start-4 p-3">
             <img :src="static_url+'/Bien-VerdeO.png'" class="m-auto" alt="Bien para Bien" />
           </div>
         </div>
         <div class="col-md-8 p-3">
           <div class="card-body text-start">
             <h4 class="text-bpb-green-02 fw-bold mb-4">PREMIUM</h4>

             <ul>
               <li class="h5 fw-light">Crédito de liquidez desde 7 MDP hasta 100 MDP.</li>
               <li class="h5 fw-light">Gasto único de administración desde el 4.9%-6%.</li>
               <li class="h5 fw-light">Plazo hasta 10 años.</li>
               <li class="h5 fw-light">Aforo desde 2 a 1.</li>
               <li class="h5 fw-light">Ingresos comprobables desde 300 mil.</li>
               <li class="h5 fw-light">Edad desde 18 a 60 Años.</li>
               <li class="h5 fw-light">Para todo tipo de persona fiscal.</li>
               <li class="h5 fw-light">Tasas del 27% al 33%.</li>
               <li class="h5 fw-light">Buen buró.</li>
             </ul>

             <span class="fw-light mt-3">** Casa, Departamento, Oficina, Local comercial, Hoteles, Naves industriales, Terrenos y Bodegas</span>

           </div>
         </div>
       </div>
     </div>
     <!-- PRODUCTO PREMIUM -->


     <!-- PRODUCTO FLEX -->
     <div class="card border-0 rounded-4 mb-3 mx-auto" v-if="productoActivo === 'flex'">
       <div class="row g-0">
         <div class="col-md-3">
           <div class="bg-bpb-green-02 d-flex h-100 rounded-start-4 p-3">
             <img :src="static_url+'/Bien-VerdeO.png'" class="m-auto" alt="Bien para Bien" />
           </div>
         </div>
         <div class="col-md-8 p-3">
           <div class="card-body text-start">
             <h4 class="text-bpb-green-02 fw-bold mb-4">FLEX</h4>

             <ul>
               <li class="h5 fw-light">Crédito de liquidez desde 1 MDP hasta 100 MDP.</li>
               <li class="h5 fw-light">Comisión por apertura desde el 4.9%-6%.</li>
               <li class="h5 fw-light">Plazo hasta 36 meses.</li>
               <li class="h5 fw-light">Con garantía** fiduciaria.</li>
               <li class="h5 fw-light">Aforo desde 3 a 1.</li>
               <li class="h5 fw-light">NA ingresos mínimos.</li>
               <li class="h5 fw-light">No hay máximo de edad cliente.</li>
               <li class="h5 fw-light">Para todo tipo de persona fiscal.</li>
               <li class="h5 fw-light">Tasa anual variable desde TIIE + 15%.</li>
               <li class="h5 fw-light">Flexibilidad en buró.
               </li>
             </ul>

             <span class="fw-light mt-3">** Casa, Departamento, Oficina, Local comercial, Hoteles, Naves industriales, Terrenos y Bodegas</span>

           </div>
         </div>
       </div>
     </div>
     <!-- PRODUCTO FLEX -->

     <!-- PRODUCTO HIPOTECARIO -->
     <div class="card border-0 rounded-4 mb-3 mx-auto" v-if="productoActivo === 'hipotecario'">
       <div class="row g-0">
         <div class="col-md-3">
           <div class="bg-bpb-green-02 d-flex h-100 rounded-start-4 p-3">
             <img :src="static_url+'/Bien-VerdeO.png'" class="m-auto" alt="Bien para Bien" />
           </div>
         </div>
         <div class="col-md-8 p-3">
           <div class="card-body text-start">
             <h4 class="text-bpb-green-02 fw-bold mb-4">HIPOTECARIO</h4>

             <ul>
               <li class="h5 fw-light">Crédito de liquidez y adquisición de vivienda desde 500mil.</li>
               <li class="h5 fw-light">Plazo hasta 15 años.</li>
               <li class="h5 fw-light">Con garantía (Casa habitación).</li>
               <li class="h5 fw-light">Hasta el 80% del valor del inmueble.</li>
               <li class="h5 fw-light">Ingresos comprobables.</li>
               <li class="h5 fw-light">Buen Buró.</li>
               <li class="h5 fw-light">Persona Física.</li>
               <li class="h5 fw-light">Tasas del 12.25% al 13.75% fija.</li>
             </ul>


           </div>
         </div>
       </div>
     </div>
     <!-- PRODUCTO HIPOTECARIO -->


     <nav class="text-center">
       <button class="btn text-bpb-white" :class=" {'active': productoActivo === 'bien-para-bien'}" @click="productoActivo = 'bien-para-bien'">Bien para Bien</button>
       <!--<button class="btn text-bpb-white" :class=" {'active': productoActivo === 'libre-sin-garantia'}" @click="productoActivo = 'libre-sin-garantia'">Libre sin garantía</button>-->
       <button class="btn text-bpb-white" :class=" {'active': productoActivo === 'premium'}" @click="productoActivo = 'premium'">Premium</button>
       <button class="btn text-bpb-white" :class=" {'active': productoActivo === 'flex'}" @click="productoActivo = 'flex'">Flex</button>
       <button class="btn text-bpb-white" :class=" {'active': productoActivo === 'hipotecario'}" @click="productoActivo = 'hipotecario'">Hipotecario</button>
     </nav>
   </div>
  </section>

</template>

<script>

export default {
  name: 'Productos',
  data() {
    return {
      static_url: process.env.VUE_APP_STATIC_URL,
      productoActivo: 'bien-para-bien'
    }
  }
}

</script>

<style scoped lang="scss">

section{
  padding: 60px 0;
}


.card{
  max-width: 900px;
}

img{
  width: 100%;
  max-width: 150px;
}

button{
  margin: 10px;
  background: transparent;
  color: $bpb-white;
  border: solid 1px $bpb-white !important;
  border-radius: 20px;
  padding: 0 20px;
  font-weight: bold;
  min-width: 150px;
}

.active{
  background: $bpb-white !important;
  color: black !important;
}


</style>